import React, { useEffect } from "react";
import bannerImg from "../assets/images/GUI_1.png";
import uperBoxImg1 from "../assets/images/Cursor in Window.png";
import uperBoxImg2 from "../assets/images/Drafting Compass.png";
import uperBoxImg3 from "../assets/images/Post Ads.png";
import lowerBoxImg1 from "../assets/images/Guarantee.png";
import lowerBoxImg2 from "../assets/images/Stopwatch.png";
import lowerBoxImg3 from "../assets/images/openai-logo 5.png";
import lowerBoxImg4 from "../assets/images/Handshake Heart.png";
import bottomBannerImg1 from "../assets/images/talent_verse_slika.png";
import bottomBannerImg2 from "../assets/images/AI_image_slika.png";
import bottomBannerImg3 from "../assets/images/one_home_slika.png";
import openAiLogo from "../assets/images/openai-logo 6.png";
import { MobileHeader } from "../components/Header";
import Container from "../components/Container";
import { Link, useLocation } from "react-router-dom";
import useDocumentTitle from "../helper/useDocumentTitle";

function Home() {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hash) {
      let targetElement = document.getElementById(
        window.location.hash.substring(1),
      );
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 100,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  useDocumentTitle("GROV | Home");

  let titleText = "AUTOMATE IDEAS WITH".split(" ");
  let titleTextRed = "ARTIFICIAL INTELLIGENCE".split(" ");
  let subtitleText = "Changing how can AI technology help businesses and their development".split(" ");

  return (
    <div className="flex flex-col gap-16 sm:gap-24">
      <MobileHeader />
      <Container>
        <div className="sm:h-[calc(100vh-142px)] flex justify-center items-center sm:mb-[80px]">
          <div className="flex flex-col md:flex-row px-3 sm:px-0 justify-between sm:items-stretch items-center gap-12 sm:gap-0 sm:mt-6">
            <div className="md:w-1/2 flex flex-col gap-4 justify-end">
              <h1 className="text-4xl sm:text-5xl lg:text-[62px] font-bold text-center md:text-start flex flex-wrap md:items-start md:justify-start items-center justify-center md:max-w-full max-w-md leading-tight">
                {titleText.map((text, index) => (
                  <span key={index} className={""}>
                    {text}
                    {"\u00A0"}
                  </span>
                ))}
                {titleTextRed.map((text, index) => (
                  <span key={index} className={"text-primary"}>
                    {text}
                    {index === 1 ? "" : "\u00A0"}
                  </span>
                ))}
              </h1>
              {/* Podnaslov z možnostjo ročne nastavitve velikosti in dvema vrsticama */}
              <h2 className="text-white text-center md:text-start font-medium mt-10 leading-snug" style={{ fontSize: "22px" }}>
                Changing how AI technology <br /> can help businesses and their development.
              </h2>
              <div className="flex sm:gap-6 gap-4 w-full justify-center text-[15px] md:justify-start pt-10">
                <Link to={"https://n2twmrt3ts5.typeform.com/to/WxiL0G3c"}>
                  <div className="my-btn text-center w-[120px] py-2 rounded-lg text-black  bg-white font-bold">
                    New project
                  </div>
                </Link>
                <Link to={"/o-nas"}>
                  <div className="my-btn w-[120px] py-2 text-center font-bold bg-[#E5313A] rounded-lg hover:bg-primary hover:transform()">
                    About us
                  </div>
                </Link>
              </div>
            </div>
            <div
              className={
                "md:w-1/2 flex md:justify-end md:items-start justify-center sm:px-0 px-2 items-center sm:pb-6 pt-3"
              }
            >
              <img src={bannerImg} className={"sm:w-[470px] w-full"} />
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div
          id="storitve"
          className="hidden sm:flex flex-col md:flex-row gap-6 justify-around"
        >
          <UpperBox
            title="WEB DEVELOPMENT"
            to="/spletni-razvoj"
            src={uperBoxImg1}
            placeholder="more"
          />
          <UpperBox
            title="GRAPHIC DESIGN"
            to="/graficna-podoba"
            src={uperBoxImg2}
            placeholder="more"
          />
          <UpperBox
            title="ADVERTISING"
            to="/oglasevanje"
            src={uperBoxImg3}
            placeholder="more"
          />
        </div>
      </Container>

      <Container>
        <div className="flex justify-center px-3 sm:px-0">
          <div className="max-w-[810px] flex flex-col gap-9 sm:gap-10">
            <div className="flex flex-col justify-center items-center gap-6 border border-white w-full rounded-lg py-6 pt-12">
              <div className="flex flex-col gap-4 sm:px-12 px-4">
                <span className="text-4xl sm:text-5xl lg:text-6xl font-bold text-center">
                  Why choose us?
                </span>
                <span className="text-center sm:text-xl font-bold leading-snug">
                  We are a digital agency that deals with the entire process
                  of creating, maintaining, and advertising companies and brands. Our
                  websites are known to visitors for their high loading speeds
                  and modern methods to improve sales.{" "}
                </span>
              </div>
              <button className="border grow border-primary rounded-full whitespace-nowrap font-semibold sm:px-4 px-2 sm:py-2 py-1 text-[5px] min-[320px]:text-[8px] sm:text-base flex justify-center items-center">
                GROV uses artificial intelligence model GPT-4 |{" "}
                <img src={openAiLogo} className="sm:max-w-[69px] sm:max-h-[20px] max-w-[34.5px] max-h-[10px]" />
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-9 sm:gap-8 sm:gap-x-12">
              <LowerBox title="High quality" src={lowerBoxImg1} />
              <LowerBox title="Fast and responsive" src={lowerBoxImg2} />
              <LowerBox title="We have an advantage" src={lowerBoxImg3} />
              <LowerBox title="We advise" src={lowerBoxImg4} />
              <div className="border border-white sm:min-w-[231px] sm:col-span-2 min-h-[166px] flex gap-6 justify-center py-8 items-center p-4 rounded-lg font-bold">
                <div className="sm:max-w-[648px] gap-6 items-center flex flex-col sm:flex-row sm:justify-between grow">
                  <div className="flex flex-col">
                    <span className="text-6xl font-bold text-primary">90</span>
                    <span className="text-xl w-[150px]">+Done websites</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-6xl font-bold text-primary">3000</span>
                    <span className="text-xl w-[150px]">+Social posts</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-6xl font-bold text-primary">500</span>
                    <span className="text-xl w-[150px]"> +Customers </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container className={"md:pt-32 pt-10"}>
        <div
          id="projekti"
          className="flex flex-col px-3 sm:px-0 lg:gap-36 md:gap-24 sm:gap-14 gap-24 md:pb-24 sm:pb-12 pb-8"
        >
          <BottomBanner
            src={bottomBannerImg1}
            placeholder="open"
            link={"https://talent-verse.xyz/"}
            title="Talent Verse"
          />
          <BottomBanner
            src={bottomBannerImg2}
            placeholder="open"
            title="AI Image"
            link={"https://fusion.framer.website/"}
            reverse
          />
          <BottomBanner
            src={bottomBannerImg3}
            placeholder="open"
            title="One home"
            link={"https://fabric.so/"}
          />
        </div>
      </Container>
    </div>
  );
}

// Komponente
const UpperBox = ({
  title,
  src,
  to,
  placeholder,
}: {
  title: string;
  src: string;
  placeholder: string;
  to: string;
}) => {
  return (
    <div className="border border-white sm:min-w-[231px] flex flex-col gap-10 justify-center items-center p-4 rounded-lg font-bold">
      <div className="p-4 rounded-full bg-primary shadow-lg shadow-[#FF2E2E] flex">
        <img src={src} className="max-w-[33px] max-h-[33px]" />
      </div>
      <span className="text-xl">{title}</span>
      <Link to={to}>
        <div className="my-btn px-3 w-[123px] py-1 rounded-[10px] bg-primary font-bold text-center">
          {placeholder}
        </div>
      </Link>
    </div>
  );
};

const LowerBox = ({ title, src }: { title: string; src: string }) => {
  return (
    <div className="border border-white sm:min-w-[231px] flex flex-col gap-4 justify-center items-center p-4 py-6 rounded-lg font-bold">
      <div className="p-4 rounded-full bg-primary shadow-lg shadow-[#FF2E2E] flex">
        <img src={src} className="max-w-[33px] max-h-[33px]"/>
      </div>
      <span className="text-xl sm:text-2xl">{title}</span>
    </div>
  );
};

const BottomBanner = ({
  src,
  title,
  placeholder,
  reverse,
  link,
}: {
  src: string;
  placeholder: string;
  title: string;
  reverse?: boolean;
  link: string;
}) => {
  return (
    <div
      className={`flex flex-col ${reverse ? "sm:flex-row-reverse" : "sm:flex-row"} grow w-full gap-4 sm:gap-10 justify-around lg:px-16 md:px-10`}
    >
      <div className="sm:w-7/12 sm:min-h-[340px] min-h-[200px] flex justify-center items-center bg-[#1F1F1F] grow rounded-[20px]">
        <div className="w-full h-full flex justify-center items-center px-4">
          <img
            src={src}
            className="md:w-[333px] sm:w-[250px] w-[230px] h-auto"
          />
        </div>
      </div>
      <div className="flex sm:justify-center sm:items-center w-full sm:w-5/12">
        <div className="flex flex-col gap-4 font-bold">
          <span className="text-4xl">{title}</span>
          <Link to={link} className="px-4 max-w-32 py-2.5 rounded-[10px] bg-primary font-bold flex items-center justify-between text-center gap-3 hover:scale-110 transition-all duration-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M8.16666 5.25L11.6667 8.75L8.16666 12.25"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="square"
              />
              <path
                d="M2.33334 2.3335V6.41683C2.33334 7.03567 2.57917 7.62916 3.01676 8.06675C3.45434 8.50433 4.04784 8.75016 4.66668 8.75016H11.0833"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="square"
              />
            </svg>
            <span className="text-[15px]">{placeholder}</span>
            <span className="px-1" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
